import * as React from 'react'
import {useEffect, useRef, useState} from 'react'
import {isLoggedIn, post, streamUrl} from "../utils/Utils";
import {toast} from "react-hot-toast";
import {Redirect} from "react-router";
import {useMutation} from "react-query";
import ChatHead from "../components/ChatHead/ChatHead";
import ChatWindow from "../components/ChatWindow/ChatWindow";
import ChatBox from "../components/ChatBox/ChatBox";

const ChatPage = () => {
    const [chats, setChats] = useState([]);
    const [chatOpened, setChatOpened] = useState(false);
    const [iccId, setIccId] = useState('');
    const [conversations, setConversations] = useState(0);
    const doSendChat = useMutation(message => post(`simcards/${iccId}/sms`, message));
    const messagesEndRef = useRef(null);

    useEffect(() => scrollToBottom(), [chats]);

    useEffect(() => {
        if (conversations > 0 && iccId !== '') {
            setChatOpened(false);
            toast.loading(`Hooking you up with ${iccId}...`, {duration: 2500});

            const eventSource = new EventSource(`${streamUrl(iccId)}`);
            eventSource.onopen = onEventSourceOpen;
            eventSource.onerror = onEventSourceError;
            eventSource.onmessage = onEventSourceMessage;
            setChatOpened(true);

            return () => eventSource.close();
        }
    }, [conversations])

    const onStartConversation = (iccId) => {
        if (iccId !== '') {
            setIccId(iccId);
            setConversations(conversations => conversations + 1);
        } else {
            toast.error("Please specify ICCID to start conversation.");
        }
    }

    const onSendMessage = (chatMessage) => {
        if (chatMessage.length > 0 && iccId !== '') {
            doSendChat.mutate({message: chatMessage}, {
                onError: () => toast.error("Failed to send chat message!")
            });
        }
    }

    const onClearMessages = () => {
        setChats([]);
        toast.success("Poof! It's gone!");
    }

    const onEventSourceMessage = (event) => {
        const parsedEvent = JSON.parse(event.data);

        if (parsedEvent.eventName && parsedEvent.eventName !== 'HEARTBEAT') {
            parsedEvent.recordedAt = new Date();
            setChats(chats => [...chats, parsedEvent])
        }
    }

    const onEventSourceOpen = () => {
        setChats([]);
        toast.success("Conversation started successfully!");
    }

    const onEventSourceError = () => {
        toast.error(`Could not start conversation with ${iccId}!`);
    }

    const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({behavior: "smooth"});

    if (!isLoggedIn()) {
        return <Redirect to='/login'/>
    }

    return (
        <section className="hero is-fullheight">
            <ChatHead onStartConversation={onStartConversation}/>
            <div className="hero-foot">
                <footer className="section is-small">
                    <ChatWindow messages={chats}/>
                    <ChatBox onSendMessage={onSendMessage} onClearMessages={onClearMessages} chatOpened={chatOpened}/>
                    <div style={{float: "left", clear: "both"}} ref={messagesEndRef}/>
                </footer>
            </div>
        </section>
    );
}

export default ChatPage;