import React, {useState} from 'react';
import {onEnterKey} from "../../utils/Utils";
import PropTypes from "prop-types";

const ChatBox = ({onSendMessage, onClearMessages, chatOpened}) => {
    const [chatMessageInput, setChatMessageInput] = useState('');

    const doSendChat = () => {
        onSendMessage(chatMessageInput);
        setChatMessageInput('');
    };

    const doClearChats = () => onClearMessages();

    return (
        <>
            <div className="field has-addons is-grouped">
                <div className="control is-expanded">
                    <input
                        className="input"
                        type="text"
                        placeholder="Type your message"
                        onKeyDown={event => onEnterKey(event, doSendChat)}
                        value={chatMessageInput}
                        onChange={(event) => setChatMessageInput(event.target.value)}
                        disabled={!chatOpened}
                    />
                </div>
                <div className="control">
                    <button className="button is-info" onClick={doSendChat} disabled={!chatOpened}>
                        Send
                    </button>
                </div>
                <div className="control">
                    <button className="button is-warning" onClick={doClearChats} disabled={!chatOpened}>
                        Clear
                    </button>
                </div>
            </div>
        </>
    );
};

ChatBox.propTypes = {
    onClearMessages: PropTypes.func.isRequired,
    onSendMessage: PropTypes.func.isRequired,
    chatOpened: PropTypes.bool.isRequired
};

ChatBox.defaultProps = {};

export default ChatBox;
