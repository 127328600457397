import React from 'react';
import PropTypes from 'prop-types';
import Message from "../Message/Message";

const ChatWindow = ({messages}) => {
    const mapMessageEvent = (parsedMessage) => ({
        from: parsedMessage.from,
        message: parsedMessage.message,
        recordedAt: parsedMessage.recordedAt,
        direction: parsedMessage.from === '' || parsedMessage.from.toLowerCase() === 'you'
            ? 'sent'
            : 'received'
    });

    const mapSystemMessageEvent = (parsedMessage) => ({
        from: parsedMessage.from,
        message: parsedMessage.message,
        recordedAt: parsedMessage.recordedAt,
        direction: parsedMessage.from.toLowerCase()
    });

    const parseMessage = (rawMessage) => {
        const eventData = rawMessage.eventData;

        if (rawMessage.eventName === 'MESSAGE') {
            return mapMessageEvent(({
                from: eventData.from,
                message: eventData.message,
                recordedAt: rawMessage.recordedAt
            }));
        } else if (rawMessage.eventName === 'SYSTEM_MESSAGE') {
            return mapSystemMessageEvent(({
                from: eventData.from,
                message: eventData.message,
                recordedAt: rawMessage.recordedAt
            }));
        } else if (rawMessage.eventName === 'ERROR') {
            return mapSystemMessageEvent(({
                from: eventData.from,
                message: eventData.message,
                recordedAt: rawMessage.recordedAt
            }));
        }
    }

    const parsedMessages = messages.map(message => parseMessage(message));

    const messageComponents = parsedMessages.map((parsedMessage, id) =>
        <Message
            key={id}
            message={parsedMessage.message}
            id={id}
            from={parsedMessage.from}
            direction={parsedMessage.direction}
            recordedAt={parsedMessage.recordedAt}
        />
    );

    return (messageComponents);
};

ChatWindow.propTypes = {
    messages: PropTypes.array.isRequired
};

ChatWindow.defaultProps = {
    message: []
};

export default ChatWindow;
