import React from 'react';
import PropTypes from 'prop-types';
import ReactTimeAgo from "react-time-ago";
import {toast} from "react-hot-toast";

const getMessageColor = (direction) => {
    switch (direction) {
        case 'sent':
            return 'is-info';
        case 'received':
            return 'is-success';
        case 'system':
            return 'is-dark';
        case 'error':
            return 'is-danger';
    }
}

const getSubtextPrefix = (direction) => {
    switch (direction) {
        case 'sent':
            return 'Sent ';
        case 'received':
            return 'Received ';
        case 'system':
            return 'System • Received ';
        case 'error':
            return 'Error • Received ';
    }
}

const copyMessage = (message) => navigator.clipboard.writeText(message).then(() => toast.success("Raw message copied to clipboard!"));

const formatText = (text) => text.match(/.{1,75}/g).map(text => text.trim()).join("\n");

const Message = ({id, from, direction, message, recordedAt}) => {
    const messageColor = getMessageColor(direction);
    const subtextPrefix = getSubtextPrefix(direction);
    const formattedMessage = formatText(message);
    const copyMessageIcon = <i
        title="Copy raw message"
        className="fas fa-copy is-clickable"
        onClick={() => copyMessage(message)}
    />;

    return (
        <p style={{
            whiteSpace: 'pre-line',
            padding: '.25em',
            textAlign: direction === 'sent' ? 'right' : 'left',
            overflowWrap: 'break-word'
        }}>
            <span className={`tag chat-bubble is-size-6 is-medium ${messageColor}`}>{formattedMessage}</span>
            <p>{subtextPrefix} <ReactTimeAgo date={recordedAt}/> • {copyMessageIcon}</p>
        </p>
    );
};

Message.propTypes = {
    id: PropTypes.number.isRequired,
    from: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    recordedAt: PropTypes.any.isRequired
};

Message.defaultProps = {
    direction: 'sent'
};

export default Message;
