import * as React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import LoginPage from "../pages/LoginPage";
import ChatPage from "../pages/ChatPage";

export const AppRouter = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={LoginPage}/>

                <Switch>
                    <Route exact path="/" component={ChatPage}/>
                </Switch>
            </Switch>
        </BrowserRouter>
    )

}