import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {toast} from "react-hot-toast";
import {logout, onEnterKey} from "../../utils/Utils";


const ChatHead = ({onStartConversation}) => {
    const [iccIdInput, setIccIdInput] = useState('');

    const doStartConversation = () => {
        if (iccIdInput.length === 19) {
            onStartConversation(iccIdInput);
        } else {
            toast.error("Please input a valid ICCID.");
        }
    }

    const doLogout = () => logout();

    return (
        <div className="hero-head">
            <header className="hero is-primary is-bold">
                <div className="hero-body">
                    <div className="container">
                        <p className="title is-2 has-text-weight-light">
                            Cobira Chat
                        </p>
                        <div className="field has-addons is-grouped">
                            <p className="control has-icons-left is-expanded">
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="ICCID"
                                    onChange={(event) => setIccIdInput(event.target.value)}
                                    onKeyDown={event => onEnterKey(event, doStartConversation)}
                                />
                                <span className="icon is-small is-left"><i className="fas fa-sim-card"></i></span>
                            </p>
                            <div className="control">
                                <button className="button is-info" onClick={doStartConversation}>
                                    Start Chat
                                </button>
                            </div>
                            <div className="control">
                                <button className="button is-dark" onClick={doLogout}>
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
};

ChatHead.propTypes = {
    onStartConversation: PropTypes.func.isRequired
};

ChatHead.defaultProps = {};

export default ChatHead;
