import * as React from 'react'
import {useState} from 'react'
import {toast} from "react-hot-toast";
import {cmpUrl, isLoggedIn, post, setAuthenticated, setCredentials} from "../utils/Utils";
import {useMutation} from "react-query";
import {Redirect} from "react-router";

const LoginPage = () => {
    const [integrationId, setIntegrationId] = useState('');
    const [integrationSecret, setIntegrationSecret] = useState('');

    const authentication = useMutation(() => post('authentication', undefined));

    if (isLoggedIn()) {
        return <Redirect to='/'/>
    }

    const checkCredentials = () => {
        if (integrationId !== '' && integrationSecret !== '') {
            setCredentials(integrationId, integrationSecret);
            authentication.mutate({}, {
                onSuccess: () => successfulAuthentication(),
                onError: () => toast.error("Failed to authenticate. Please check your credentials.")
            });
        }
    }

    const successfulAuthentication = () => {
        setAuthenticated();
        toast.success("Welcome to Cobira Chat!");
        return <Redirect to='/'/>
    }

    return (
        <section className="hero is-primary is-bold is-fullheight">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <h1 className="title is-2 has-text-weight-light">
                        Cobira Chat
                    </h1>
                    <h2 className="subtitle is-4 has-text-weight-light">
                        You Must Authenticate 🛂
                    </h2>
                    <p className="">Navigate to <a className="has-text-link" href={`${cmpUrl()}/integrations`} target="_blank" rel="noopener noreferrer">Cobira Integration</a> and fill in your details below.</p>

                    <div className="field">
                        <label className="label has-text-left">Integration ID</label>
                        <div className="control">
                            <input className="input" type="text" placeholder="Integration ID" onChange={(event) => setIntegrationId(event.target.value)}/>
                        </div>
                    </div>

                    <div className="field">
                        <label className="label has-text-left">Integration Secret</label>
                        <div className="control">
                            <input className="input" type="password" placeholder="Integration Secret" onChange={(event) => setIntegrationSecret(event.target.value)}/>
                        </div>
                    </div>

                    <button className='button is-info is-medium' onClick={checkCredentials}>
                        Authenticate
                    </button>
                </div>
            </div>
        </section>
    );
}

export default LoginPage;