import './App.scss';
import {Toaster} from "react-hot-toast";
import {QueryClient, QueryClientProvider} from "react-query";
import {AppRouter} from "./routes/AppRouter";
import {useEffect} from "react";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false
        }
    }
});

const App = () => {
    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
            document.title = `${document.title} [${process.env.REACT_APP_ENVIRONMENT}]`;
        }
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Toaster position="top-right" reverseOrder={true}/>
            <AppRouter/>
        </QueryClientProvider>
    );
}

export default App;
